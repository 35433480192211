@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: 'Open Sans', sans-serif !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 0px !important;
  width: 100% !important;
  max-width: 800px !important;
}
.MuiTableCell-root {
  border-top: 1px solid rgba(0, 0, 0, 0.342);
  border-bottom: 1px solid rgba(0, 0, 0, 0.342);
}
.last-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.342);
  border-radius: 0px 10px 10px 0px;
}
.first-cell {
  overflow: hidden;
  /* padding-left: 8px; */
  border-radius: 10px 0px 0px 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.342);
}
.menu-tab-btn {
  background-color: #efefef;
  border: 2px solid #efefef;
}
.MuiButtonBase-root {
  box-shadow: none;
}
.gradiant-container {
  z-index: 2;
  position: relative;
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgba(245, 245, 245, 1) 50%, rgba(255, 255, 255, 0) 50%);
}
@media only screen and (max-width: 1220px) {
  .gradiant-container {
    background: rgb(245, 245, 245);
    background: linear-gradient(90deg, rgba(245, 245, 245, 1) 55%, rgba(249, 250, 251, 0) 45%);
  }
}
@media only screen and (max-width: 1050px) {
  .gradiant-container {
    background: rgb(245, 245, 245);
    background: linear-gradient(90deg, rgba(245, 245, 245, 1) 65%, rgba(249, 250, 251, 0) 35%);
  }
}
@media only screen and (max-width: 780px) {
  .gradiant-container {
    background: rgb(245, 245, 245);
    background: linear-gradient(90deg, rgba(245, 245, 245, 1) 100%, rgb(249, 250, 251) 100%);
  }
}
.MuiButton-outlinedPrimary:hover {
  border-color: #00a4db;
  color: #00a4db;
  background: transparent;
}
.popover-item,
.text-muted {
  color: #797979 !important;
}
a:hover {
  color: #00a4db !important;
}
.popover-item:hover,
a > svg:hover {
  color: #00a4db !important;
}
.uploader-wrapper label {
  width: 100% !important;
}

.main-wrapper > nav > div > div {
  background-color: #fff !important;
}
.my-nav-links {
  color: #797979;
}

html {
  font-size: 16px;
}

body {
  font-size: 1rem !important; /* 1rem !important !important is equal to the base font size */
}

h1 {
  font-size: 1.9rem !important; /* 2 times the base font size */
}

h2 {
  font-size: 1.6rem !important; /* 1.8 times the base font size */
}

h3 {
  font-size: 1.4rem !important; /* 1.5 times the base font size */
}

h4 {
  font-size: 1.25rem !important; /* 1.2 times the base font size */
}

h5 {
  font-size: 1.1rem !important; /* 1.1 times the base font size */
}

.MuiTypography-h6,
h6 {
  font-size: 1rem !important; /* Equal to the base font size */
}
a > b {
  font-size: 1rem !important; /* Equal to the base font size */
}
div > b {
  font-size: 1rem !important; /* Equal to the base font size */
}
p,
a,
li {
  font-size: 1rem !important; /* Equal to the base font size */
}

/* Example media queries for responsiveness */
@media (max-width: 768px) {
  body {
    font-size: 0.75rem !important; /* Adjust font size for larger screens */
  }
}

@media (max-width: 1024px) {
  body {
    font-size: 0.9rem !important; /* Adjust font size for even larger screens */
  }
}
button {
  text-transform: initial !important;
}
.MuiAccordionSummary-root:hover h4.MuiTypography-root,
.MuiAccordionSummary-root:hover svg.text-muted {
  color:#00a4db !important;
}

